<template>
  <div class="ev2-box" style="max-width: 400px">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatísticas Financeira</div>
        <div class="subtitle">Resumo sobre os clientes</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t">
      <div class="task-count">
        <div class="count">
          <div class="lbl">Total de clientes:</div>
          <div class="val-container">
            <div class="value bg-black text-white">{{ stats.total }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Ativos:</div>
          <div class="val-container">
            <div class="value started">{{ stats.ativos }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Homologando:</div>
          <div class="val-container">
            <div class="value pending">{{ stats.homolog }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Suspensos:</div>
          <div class="val-container">
            <div class="value">{{ stats.suspensos }}</div>
          </div>
        </div>
        <div class="hr" />
        <div class="header m-t">
          <div class="hcol1">
            <div class="title">Meta Financeira</div>
          </div>
          <div class="hcol2">
          </div>
        </div>
        <div class="count m-t-lg">
          <div class="lbl">Faturamento recorrente:</div>
          <div class="val-container2">
            <div class="value2">R$ {{ stats.mensalidade|moeda }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Meta:</div>
          <div class="val-container2">
            <div class="value2">R$ {{ stats.mensalidadeMeta|moeda }}</div>
          </div>
        </div>
        <div class="count m-t">
          <div class="lbl">Recebimentos em Atraso:</div>
          <div class="val-container2">
            <div class="value2">{{ stats.recebimentosEmAtrasoTotal }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Valor em atraso:</div>
          <div class="val-container2">
            <div class="value2 text-negative">R$ {{ stats.recebimentosEmAtrasoValor|moeda }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {financeiroStats} from "@/domain/gerencia/stats/services";
import SlLoading from "components/layout/components/Loading.vue";

export default {
  name: "ConsoleFinanceiroStatsMensalidade",
  components: {SlLoading},
  data () {
    return {
      loading: true,
      stats: {
        total: 0,
        ativos: 0,
        suspensos: 0,
        homolog: 0,
        mensalidade: 0,
        mensalidadeMeta: 0,
        recebimentosEmAtrasoTotal: 0,
        recebimentosEmAtrasoValor: 0
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      financeiroStats()
          .then(response => {
            this.stats = Object.assign(this.stats, response.data)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
