<template>
  <div class="ev2-box stats">
    <div class="header">
      <div class="hcol1">
        <div class="title">Algo aqui...</div>
        <div class="subtitle">Dados mais relevantes...</div>
      </div>
    </div>
    <div class="body m-t">
      Em construção.
    </div>
  </div>
</template>

<script>
export default {
  name: "Stats"
}
</script>
